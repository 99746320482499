<template>
  <div>
    <translations
      :is-new="true"
      :label="$t('t.Name')"
      :rules="nameRules"
      :translations.sync="computedName"
      :dense="dense"
    />
    <translations
      :is-new="true"
      :label="$t('t.Description')"
      :translations.sync="computedDescription"
      :dense="dense"
    />
    <picker
      :rules="required"
      :label="$t('t.WorkItemType')"
      :disabled="disabled"
      :attach="false"
      :selected-id.sync="computedWorkItemTypeId"
      :dense="dense"
      document-types="work-item-types"
      work-item-type-is-account-action
    />
    <picker
      :clearable="false"
      document-types="work-item-priorities"
      :attach="false"
      :rules="required"
      :label="$t('t.WorkItemPriorities')"
      :selected-id.sync="computedPriority"
      :dense="dense"
    />
    <picker
      document-types="groups"
      :attach="false"
      :label="$t('t.PerformedBy')"
      :selected-id.sync="computedGroupId"
      :filters="{ 'roles': rolesFilters}"
      :dense="dense"
    />
  </div>
</template>

<script>
import { CacheType } from '@/wasm/pkg'
import IncExc from '@/pages/search/controllers/inc-exc'

export default {
  inheritAttrs: false,
  components: {
    // Grid: () => import('@/components/grid'),
    Translations: () => import('@/components/translations'),
    Picker: () => import('@/components/picker')
  },
  computed: {
    computedUserId: {
      get () { return this.dataUserId },
      set (v) {
        this.dataUserId = v
        this.dataDocumentEmit()
      }
    },
    computedIs: {
      get () { return this.dataGroupId },
      set (v) {
        this.dataGroupId = v
        this.dataDocumentEmit()
      }
    },
    computedGroupId: {
      get () { return this.dataGroupId },
      set (v) {
        this.dataGroupId = v
        this.dataDocumentEmit()
      }
    },
    computedPriority: {
      get () { return this.dataPriority },
      set (v) {
        this.dataPriority = v
        this.dataDocumentEmit()
      }
    },
    computedTimeoutDays: {
      get () { return this.dataTimeoutDays },
      set (v) {
        this.dataTimeoutDays = v
        this.dataDocumentEmit()
      }
    },
    computedName: {
      get () { return this.dataName || {} },
      set (v) {
        this.dataName = this.lodash.cloneDeep(v)
        this.dataDocumentEmit()
      }
    },
    computedDescription: {
      get () { return this.dataDescription || {} },
      set (v) {
        this.dataDescription = this.lodash.cloneDeep(v)
        this.dataDocumentEmit()
      }
    },
    computedWorkItemTypeId: {
      get () { return this.dataWorkItemTypeId },
      set (v) {
        this.dataWorkItemTypeId = v
        this.dataDocumentEmit()
      }
    },
    defaultWorkItem () {
      return {
        type: 'create-work-item',
        description: {},
        groupId: null,
        userId: null,
        name: {},
        priority: 1,
        timeoutDays: 0,
        workItemTypeId: null
      }
    },
    rolesFilters () {
      const roles = new IncExc()
      roles.include(['sales-reps', 'division-supervisors', 'users', 'agent-supervisors'])
      return roles
    },
    nameRules () {
      const MIN = 1
      const MAX = 100
      return [
        v => !!v.length || this.$t('t.IsRequired'),
        v => (v.length >= MIN && v.length <= MAX) || this.$t('t.Length')
      ]
    },
    daysRules () {
      return [
        v => Number.isInteger(v) || this.$t('t.MustBeInteger'),
        v => v >= 0 || this.$t('t.MustBeGreaterOrEqualTo', { value: 0 }),
        v => v <= 92233720368547 || this.$t('t.MustBeLesserThan', { value: 92233720368547 })
      ]
    }
  },
  data () {
    return {
      dataDescription: {},
      dataGroupId: null,
      dataUserId: null,
      dataName: {},
      dataPriority: 1,
      dataTimeoutDays: 0,
      dataWorkItemTypeId: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      externalUserCacheType: CacheType.UserRef,
      required: [v => (!this.lodash.isNil(v) && v !== '') || this.$t('t.IsRequired')]
    }
  },
  methods: {
    emitDocument () {
      const doc = {
        type: 'create-work-item',
        name: this.dataName,
        description: this.dataDescription,
        userId: this.dataUserId,
        groupId: this.dataGroupId,
        workItemTypeId: this.dataWorkItemTypeId,
        priority: this.dataPriority,
        timeoutDays: this.dataTimeoutDays
      }

      if (!this.lodash.isEqual(this.document, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  props: {
    document: Object,
    disabled: Boolean,
    dense: Boolean,
    readonly: Boolean
  },
  watch: {
    document: {
      handler (v) {
        this.dataGroupId = v?.groupId
        this.dataDescription = v?.description ? this.lodash.cloneDeep(v.description) : {}
        this.dataUserId = v?.userId
        this.dataName = v?.name ? this.lodash.cloneDeep(v.name) : {}
        this.dataPriority = v?.priority ?? 1
        this.dataTimeoutDays = v?.timeoutDays ?? 0
        this.dataWorkItemTypeId = v?.workItemTypeId
        this.dataDocumentEmit()
      },
      immediate: true
    }
  }
}
</script>
